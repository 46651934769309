import React from 'react'
import { graphql } from 'gatsby'
import Container from '../../components/container'
import GraphQLErrorList from '../../components/graphql-error-list'
import FAQ from '../../components/microsites/faq'
import SEO from '../../components/seo'
import Layout from '../../containers/micrositeLayout'
import 'typeface-montserrat'

export const query = graphql`
  query MicrositesFaqTemplateQuery($id: String!) {
    microsite: sanityMicrosite(id: { eq: $id }) {
      _id
      _rawAboutUs
      _rawContactUs
      _rawWelcomeMessage
      _rawGettingStartedMessage
      _rawFaqs
      _rawPackages
      about_us {
        style
        _type
      }
      mainBannerImage {
        asset {
          _id
          gatsbyImageData(layout: CONSTRAINED, width: 900)
        }
        alt
      }
      company_title
      contact_us {
        _type
        style
      }
      getting_started_message {
        style

        _type
      }
      faqs {
        _key
      }
      welcome_message {
        _type
        style
      }
      title
      nav_options {
        standalonePages
        navPages
      }
      slug {
        current
      }
      packages {
        CTAbuttonText
        CTAbuttonType
        _type
        title
        packageType
        packageDetails {
          _type
          style
        }
      }
      id
    }
  }
`
const MicrositeFaq = (props) => {
  const { data, errors } = props
  const microsite = data && data.microsite
  return (
    <Layout {...microsite}>
      {errors && <SEO title="GraphQL Error" />}
      {microsite && <SEO title={microsite.title || 'Untitled'} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      {microsite && <FAQ {...microsite} />}
    </Layout>
  )
}

export default MicrositeFaq
