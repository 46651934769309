import React from 'react'
import tw from 'tailwind.macro'
// import BlockContent from '../block-content'
import FaqAccordion from './faqAccordion'

function Faq(props) {
  const SectionContainer = tw.section`
        text-black max-w-7xl w-full font-display px-4
    `
  return (
    <SectionContainer>
      <div title="Frequently Asked Questions" className="mb-4">
        <h1 className="mt-2 px-0 py-6 text-xl md:text-2xl lg:text-3xl font-normal">
          Frequently Asked Questions
        </h1>
        <span id="faq"></span>
        <FaqAccordion {...props}></FaqAccordion>
      </div>
    </SectionContainer>
  )
}

export default Faq
